<template>
  <div>
    <div style="height: auto;padding-bottom: 30px; border-bottom: none" class="common_liucheng">
      <span
      >推荐科研专家
        <div class="arrow_ld">
          <span @click="backPage('expert')"
          ><Icon type="ios-arrow-back"
          /></span>
          <span @click="forwardPage('expert')"
                style="margin-left: 10px"><Icon type="ios-arrow-forward"
          /></span></div
        ></span>
      <div class="neirong">
        <div class="nei_rong_box">
          <Row :gutter="16">
            <Col span="6" v-for="(item, index) in expertList" :key="index"   >
              <div
                  class="result-patent-hover"
                  style="
                  height: auto;
                  border: 1px solid rgb(218, 226, 229);
                  margin-bottom: 20px;
                "
              >
                <div
                    style="
                    height: 90px;
                    background-color: rgb(247, 248, 252);
                    display: flex;
                    align-items: center;
                  "
                >
                  <span
                      style="
                      font-size: 18px;
                      display: inline-block;
                      margin-left: 10px;
                      margin-right: 5px;
                    "
                  >{{ item.expertName }}</span
                  ><span style="font-size: 14px">{{ item.unitsName }}</span>
                </div>
                <div style="height: 130px">
                  <div
                      style="
                      height: 60px;
                      display: flex;
                      align-items: center;
                      margin-left: 10px;
                    "
                  >
                    推荐指数：<span>{{ item.expertsCount }}</span>
                  </div>
                  <div
                      style="
                      height: 42px;
                      padding-left: 10px;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      display: -webkit-box;
                      -webkit-line-clamp: 2;
                      -webkit-box-orient: vertical;
                    "
                  >
                    <span>
                      技术关键词：{{item.keywords || '暂无'}}
                    </span>
                  </div>
                </div>
                <div
                    @click.stop="openZoosUrl"
                    style="
                    cursor: pointer;
                    height: 39px;
                    text-align: center;
                    line-height: 39px;
                    color: white;
                    background-color: rgb(24,144,255);
                    border: none;
                  "
                >
                  联系专家
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
    <div style="height: auto;padding-bottom: 30px; border-bottom: none" class="common_liucheng">
      <span
      >推荐科研成果
        <div class="arrow_ld">
          <span @click="backPage('result')"
          ><Icon type="ios-arrow-back"
          /></span>
          <span @click="forwardPage('result')"
                style="margin-left: 10px"><Icon type="ios-arrow-forward"
          /></span></div
        ></span>
      <div class="neirong">
        <div class="nei_rong_box">
          <Row :gutter="16">
            <Col span="6" v-for="(item, index) in resultList" :key="index">
              <div
                  class="result-patent-hover"
                  @click="seeResult(item)"
                  style="
                  height: auto;
                  border: 1px solid rgb(218, 226, 229);
                  margin-bottom: 20px;
                "
              >
                <div
                    style="
                    height: 90px;
                    background-color: rgb(247, 248, 252);
                    display: flex;
                    align-items: center;
                  "
                >
                  <span class="span_cg">{{ item.title }}</span>
                  <span style="font-size: 14px" class="span_yyjs">{{
                      item.achieveType
                    }}</span>
                </div>
                <div style="height: 130px">
                  <div
                      style="
                      margin-top: 20px;
                      height: 50px;
                      display: flex;
                      align-items: center;
                      margin-left: 10px;
                             overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    "
                  >
                    <span
                        style="
                                     overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        text-align: left;
                        line-height: 25px;
                      "
                    >归属高校：{{ item.colleges }}</span
                    >
                  </div>
                  <div
                      style="
                      height: 42px;
                      padding-left: 10px;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      display: -webkit-box;
                      -webkit-line-clamp: 2;
                      -webkit-box-orient: vertical;
                    "
                  >
                    技术关键词：{{ item.keywords }}
                  </div>
                </div>
                <div
                    @click="openZoosUrl"
                    style="
                    cursor: pointer;
                    height: 39px;
                    text-align: center;
                    line-height: 39px;
                    color: white;
                    background-color: rgb(24,144,255);
                    border: none;
                  "
                >
                  联系成果
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
    <div style="height: auto; border-bottom: none;margin-bottom: 60px" class="common_liucheng">
      <span
      >推荐科研专利
        <div class="arrow_ld">
          <span @click="backPage('patent')"
          ><Icon type="ios-arrow-back"
          /></span>
          <span @click="forwardPage('patent')"
                style="margin-left: 10px"><Icon type="ios-arrow-forward"
          /></span></div
        ></span>
      <div class="neirong">
        <div class="nei_rong_box" style="height: auto;padding-bottom: 30px;">
          <Row :gutter="16">
            <Col span="6" v-for="(item, index) in patentList" :key="index">
              <div
                  class="result-patent-hover"
                  @click="seePatent(item)"
                  style="
                  height: auto;
                  border: 1px solid rgb(218, 226, 229);
                  margin-bottom: 20px;
                "
              >
                <div
                    style="
                    height: 90px;
                    background-color: rgb(247, 248, 252);
                    display: flex;
                    align-items: center;
                  "
                >
                  <span class="span_cg">{{ item.ti }}</span>
                  <span
                      style="font-size: 14px; width: 50px"
                      class="span_yyjs"
                  >{{ item.lastLegalStatus }}</span
                  >
                </div>
                <div style="height: 205px">
                  <div
                      style="
                      height: 50px;
                      display: flex;
                      align-items: center;
                      margin-left: 10px;
                    "
                  >
                    <span
                        style="
                        display: inline-block;
                        width: 100px;
                        text-align: left;
                      "
                    >专利申请号：</span
                    ><span>{{ item.an }}</span>
                  </div>
                  <div
                      style="
                      height: 50px;
                      display: flex;
                      align-items: center;
                      margin-left: 10px;
                    "
                  >
                    <span
                        style="
                        display: inline-block;
                        width: 100px;
                        text-align: left;
                      "
                    >专利类型：</span
                    ><span>{{ item.patType }}</span>
                  </div>
                  <div
                      style="
                      height: 50px;
                      display: flex;
                      align-items: center;
                      margin-left: 10px;
                             overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    "
                  >
                    <span
                        style="
                                       overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        text-align: left;
                        line-height: 25px;
                      "
                    >归属高校：{{ item.pa }}</span
                    >
                  </div>
                  <div
                      style="
                      height: 50px;
                      align-items: center;
                      margin-left: 10px;

                    "
                  >
                    <span
                        style="
                        display: inline-block;
                overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        text-align: left;
                      "
                    >成果发明人：{{ item.pin }}</span
                    >
                  </div>
                </div>
                <div
                    @click="openZoosUrl"
                    style="
                    cursor: pointer;
                    height: 39px;
                    text-align: center;
                    line-height: 39px;
                    color: white;
                    background-color: rgb(24,144,255);
                    border: none;
                  "
                >
                  联系专利
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
    <div
        class="common_liucheng"
        style="
       /*padding-bottom: 40px;*/
        padding-top: 60px;
        display: flex;
        overflow: hidden;
        align-items: center;
        border-bottom: none !important;
      "
    >
      <div style="padding-bottom: 10px;padding-top: 40px; width: 60%">
        <table
            class="qiye"
            border="0"
            cellspacing="0"
            cellpadding="0"
            border-collapse="collapse"
        >
          <tr style="height: 50px">
            <td style="width: 100px">企业名称</td>
            <td>{{ enterpriseInfo.enterpriseName }}</td>
            <td>所属科技领域</td>
            <td>{{ enterpriseInfo.satTerritory || '--'}}</td>
          </tr>
          <tr style="height: 50px; background-color: #f3fcfc">
            <td style="width: 100px">行政区划</td>
            <td>{{ enterpriseInfo.administrativeDivision }}</td>
            <td>企业类型</td>
            <td>{{ enterpriseInfo.enterpriseType }}</td>
          </tr>
          <tr style="height: 50px">
            <td style="width: 100px">企业地址</td>
            <td>{{ enterpriseInfo.site }}</td>
            <td>营业期限</td>
            <td>{{ enterpriseInfo.bussinessTerm }}</td>
          </tr>
          <tr style="padding: 0 10px">
            <td style="width: 100px; background-color: #f3fcfc">经营范围</td>
            <td colspan="3">
              <div
                  style="
                  /*height: 230px;*/
                  padding: 0 10px;
                "
              >
                <span style="    overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 11;
                  -webkit-box-orient: vertical;">
                    {{ enterpriseInfo.businessScope }}
                </span>

              </div>
            </td>
          </tr>
        </table>
      </div>
      <div style="height: 370px; width: 40%;" ref="wd"></div>
    </div>
  </div>
</template>

<script>
import {get} from "../../../plugins/http/http";
import {
  isCorrectPassword,
  isPhoneNumber,
  successToast,
  warnToast,
  errorToast,
} from "@/plugins/tools/util";
import {mapGetters} from "vuex";
import {
  getExpertMatch,
  getPatentMatch,
  getResultsMatch,
  getEnterpriseInfo,
  getWordCloud,
} from "../../../plugins/api/EnterpriseSchoolConnection";
import {qxtUrl} from "../../../plugins/http/baseUrl";

export default {
  name: "EnterpriseInformationMatching",
  computed: {
    ...mapGetters({mbrMemberId: 'userStore/mbrMemberId'})
  },
  data() {
    return {
      patentTotal: 0,
      patentNow: 1,
      resultTotal: 0,
      resultNow: 1,
      expertTotal: 0,
      expertNow: 1,
      patentList: [],
      resultList: [],
      expertList: [],
      matchParams: {
        requestId: "",
        pageSize: 8,
        pageNum: 1,
      },
      enterpriseInfo: {
        enterpriseName: "",
        administrativeDivision: "",
        site: "",
        enterpriseType: "",
        bussinessTerm: "",
        satTerritory: "",
        businessScope: "",
      },
      totalExpertPage: "",
      totalResultPage: "",
      totalPatentPage: "",
      matchPatentParams: {
        requestId: "",
        pageSize: 8,
        pageNum: 1,
      },
      matchResultsParams: {
        requestId: "",
        pageSize: 8,
        pageNum: 1,
      },
      matchExpertParams: {
        requestId: "",
        pageSize: 8,
        pageNum: 1,
      },
      cloudData2: [],
    };
  },

  mounted() {
    // this.getMatchList();
    this.getEnterprise();
    this.getMatchList();
    this.draw()
  },
  methods: {
    expertDetail(item){
      let routeUrl = this.$router.resolve({
        path: "/home/experts",//新页面地址
        query: { shopId: item.id }//携带的参数
      });
      window.open(routeUrl.href, "_blank");
    },
    color: function () {
      return (
          "rgb(" +
          [
            Math.round(Math.random() * 250),
            Math.round(Math.random() * 250),
            Math.round(Math.random() * 250),
          ].join(",") +
          ")"
      );
    },
    async draw() {
      get(`${qxtUrl}/enterprise/enterprise/tag?memberId=${this.mbrMemberId}`).then((res) => {
        if (res.code == 0) {
          if (res.result.length > 0) {
            res.result.forEach((item, index) => {
              this.cloudData2.push({
                value: Math.round(Math.random() * 250),
                name: item.name,
                textStyle: {color: this.color()}
              })
            })
          } else {
            this.cloudData2.push({value: Math.round(Math.random() * 250), name: "无", textStyle: {color: this.color()}})
          }

        }
        this.$nextTick(() => {
          this.drawCloud(this.$refs.wd, this.cloudData2)
        })
      })
    },
    openZoosUrl() {
      openZoosUrl('chatwin');
    },
    async backPage(name) {
      switch (name) {
        case "result":
          if (this.resultNow === 1) {
            return "";
          } else {
            this.resultNow -= 1;
            this.matchParams.pageNum = this.resultNow;
            let rjson = await getResultsMatch(this.matchParams);
            if (rjson.code == 0) {
              this.resultList = rjson.result.list;
              this.resultTotal = rjson.result.totalPage;
            } else {
              warnToast(rjson.message);
            }
          }
          break;
        case "expert":
          if (this.expertNow === 1) {
            return "";
          } else {
            this.expertNow -= 1;
            this.matchParams.pageNum = this.expertNow;
            let rjson = await getExpertMatch(this.matchParams);
            if (rjson.code == 0) {
              this.expertList = rjson.result.list;
              this.expertTotal = rjson.result.totalPage;
            } else {
              warnToast(rjson.message);
            }
          }
          break;
        case "patent":
          if (this.patentNow === 1) {
            return "";
          } else {
            this.patentNow -= 1;
            this.matchParams.pageNum = this.patentNow;
            let rjson = await getPatentMatch(this.matchParams);
            if (rjson.code == 0) {
              this.patentList = rjson.result.list;
              this.patentTotal = rjson.result.totalPage;
            } else {
              warnToast(rjson.message);
            }
          }
          break;
      }
    },
    seePatent(item) {
      let routeUrl = this.$router.resolve({
        path: "/home/ResourceDetail",//新页面地址
        query: {an: item.an}//携带的参数
      });
      window.open(routeUrl.href, "_blank");

    },
    seeResult(item) {
      let routeUrl = this.$router.resolve({
        path: "/home/PatentSearchDetail",//新页面地址
        query: {id: item.id}//携带的参数
      });
      window.open(routeUrl.href, "_blank");
    },
    async forwardPage(name) {
      switch (name) {
        case "result":
          if (this.resultNow === this.resultTotal) {
            return "";
          } else {
            this.resultNow += 1;
            this.matchParams.pageNum = this.resultNow;
            let rjson = await getResultsMatch(this.matchParams);
            if (rjson.code == 0) {
              this.resultList = rjson.result.list;
              this.resultTotal = rjson.result.totalPage;
            } else {
              warnToast(rjson.message);
            }
          }
          break;
        case "expert":
          if (this.expertNow === this.resultTotal) {
            return "";
          } else {
            this.expertNow += 1;
            this.matchParams.pageNum = this.expertNow;
            let rjson = await getExpertMatch(this.matchParams);
            if (rjson.code == 0) {
              this.expertList = rjson.result.list;
              this.expertTotal = rjson.result.totalPage;
            } else {
              warnToast(rjson.message);
            }
          }
          break;
        case "patent":
          if (this.patentNow === this.patentTotal) {
            return "";
          } else {
            this.patentNow += 1;
            this.matchParams.pageNum = this.patentNow;
            let rjson = await getPatentMatch(this.matchParams);
            if (rjson.code == 0) {
              this.patentList = rjson.result.list;
              this.patentTotal = rjson.result.totalPage;
            } else {
              warnToast(rjson.message);
            }
          }
          break;
      }
    },
    async getEnterprise() {
      let json = await getEnterpriseInfo();

      if (json.code === 0) {
        this.enterpriseInfo.businessScope = json.result.businessScope || '--';
        this.enterpriseInfo.site = json.result.site || '--';
        this.enterpriseInfo.enterpriseType = json.result.enterpriseType || '--';
        this.enterpriseInfo.enterpriseName = json.result.enterpriseName || '--';
        this.enterpriseInfo.bussinessTerm = json.result.bussinessTerm || '--';
        this.enterpriseInfo.administrativeDivision =
            json.result.administrativeDivision || '--';
        this.enterpriseInfo.bussinessTerm = json.result.businessTerm || '--'
        this.enterpriseInfo.satTerritory = json.result.satTerritory || '--';
      }
    },

    async getMatchList() {
      // this.matchParams.requestId = this.enterpriseId;
      let json = await getPatentMatch(this.matchParams);
      if (json.code == 0) {
        this.patentList = json.result.list;
        this.patentTotal = json.result.totalPage;
      } else {
        warnToast(json.message);
      }

      let rjson = await getResultsMatch(this.matchParams);
      if (rjson.code == 0) {
        this.resultList = rjson.result.list;
        this.resultTotal = rjson.result.totalPage;
      } else {
        warnToast(rjson.message);
      }
      let ejson = await getExpertMatch(this.matchParams);
      if (ejson.code == 0) {
        this.expertList = ejson.result.list;
        this.expertTotal = ejson.result.totalPage;
      } else {
        warnToast(ejson.message);
      }

      let wordCloudList = await getWordCloud({requestId: ""});

      this.nowIndex = 1;
      scrollTo(0, 0);
    },
    drawCloud(wrapEl, data) {
      let myChart = this.$echarts.init(wrapEl);
      var option = {
        tooltip: {
          show: true,
        },
        series: [
          {
            name: "热词",
            type: "wordCloud",
            sizeRange: [10, 30],
            rotationRange: [-20, 20],
            shape: "circle",
            left: "center",
            top: "center",
            width: "100%",
            height: "100%",
            gridSize: 3,
            textPadding: 0,
            autoSize: {
              enable: true,
              minSize: 6,
            },
            textStyle: {
              normal: {
                color: function () {
                  return (
                      "rgb(" +
                      [
                        Math.round(Math.random() * 250),
                        Math.round(Math.random() * 250),
                        Math.round(Math.random() * 250),
                      ].join(",") +
                      ")"
                  );
                },
              },
              emphasis: {
                shadowBlur: 10,
                shadowColor: "#333",
              },
            },
            data: data,
          },
        ],
      };
      myChart.setOption(option);
    },
  },
};
</script>

<style scoped lang="scss">
.ivu-select-selection {
  border-radius: 0 !important;
}

.arrow_ld {
  display: inline-block;
  position: absolute;
  right: 0;

  & > span {
    display: inline-block;
    height: 20px;
    width: 20px;
    cursor: pointer;
    background-color: #cecece;
    text-align: center;
    line-height: 20px;
    color: white;
  }

  & > span:hover {
    background-color: #37c5cf;
  }
}

.qiye,
.qiye td {
  border: 1px solid rgb(24,144,255);
}

.qiye {
  border-bottom: none;
  border-right: none;
}

.qiye td {
  border-top: none;
  border-left: none;
  text-align: center;
}

.span_yyjs {
  display: inline-block;
  text-align: center;
  font-size: 14px;
  height: 24px;
  line-height: 20px;
  width: 64px;
  border-width: 1px;
  background-color: #fcdfc8;
  border-color: #fea464;
  border-style: solid;
  padding: 2px;
  color: black;
  opacity: 0.8;
  overflow: hidden;
  position: absolute;
  right: 20px;
}

.qiye td:nth-child(1) {
  width: 100px;
  //text-align: right;
}

.qiye td:nth-child(2) {
  width: 310px;
}

.qiye td:nth-child(3) {
  width: 130px;
}
.result-patent-hover:hover {
  box-shadow: 6px 6px 15px 0px rgba(127, 127, 127, 0.3);
}

.qiye td:nth-child(4) {
  width: 260px;
}

.span_cg {
  font-size: 18px;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.common_liucheng {
  width: 95%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  border-bottom: 2px dotted #dfe0f2 !important;

  & > span {
    display: block;
    padding-left: 5px;
    padding-top: 38px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    color: rgb(51, 51, 51);
    margin-bottom: 30px;
  }
}

.next_style {
  display: flex;
  align-items: center;
  justify-content: center;
}

.right_real_content {
  margin-bottom: 50px;
  width: 95%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 40px;
}


</style>
